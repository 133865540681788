define('ember-cli-echarts/components/echarts-chart', ['exports', 'echarts'], function (exports, _echarts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['echarts-chart'],

    option: {},
    notMerge: false,
    lazyUpdate: false,
    theme: null,
    onEvents: {},
    onChartReady: function onChartReady() {},

    showLoading: false,
    loadingOptions: {},
    opts: {},

    _chart: null,

    beforeSetup: function beforeSetup() {},
    afterSetup: function afterSetup() {},
    init: function init() {
      this._super.apply(this, arguments);
      $(window).on('resize', this.handleResize.bind(this));
    },


    eventContext: Ember.computed(function () {
      return Ember.get(this, 'targetObject') || this;
    }),

    reRender: Ember.on('didInsertElement', Ember.observer('option', 'opts', 'opts.{devicePixelRatio,renderer,width,height}', function () {
      Ember.run.scheduleOnce('render', this, this.renderChart);
    })),

    renderChart: function renderChart() {
      if (Ember.get(this, '_chart') && Ember.get(this, '_chart').isDisposed()) return;

      var selector = '#' + Ember.get(this, 'elementId');
      var $el = $(selector);
      var context = Ember.get(this, 'eventContext');
      var notMerge = Ember.get(this, 'notMerge');
      var lazyUpdate = Ember.get(this, 'lazyUpdate');
      var option = Ember.get(this, 'option');
      var theme = Ember.get(this, 'theme');
      var opts = Ember.get(this, 'opts');
      var onChartReady = Ember.get(this, 'onChartReady');
      var showLoading = Ember.get(this, 'showLoading');
      var loadingOptions = Ember.get(this, 'loadingOptions');
      var beforeSetup = Ember.get(this, 'beforeSetup');
      var afterSetup = Ember.get(this, 'afterSetup');

      var chart = _echarts.default.init($el[0], theme, opts);
      Ember.set(this, '_chart', chart);

      // before echarts setup
      Ember.run(function () {
        return beforeSetup.call(context, chart);
      });

      chart.setOption(option, notMerge, lazyUpdate);

      // set loading mask
      if (showLoading) chart.showLoading(loadingOptions || null);else chart.hideLoading();

      this.bindEvents(chart);

      if (typeof onChartReady === 'function') onChartReady(chart);

      // after echarts setup
      Ember.run(function () {
        return afterSetup.call(context, chart);
      });

      return chart;
    },
    handleResize: function handleResize() {
      var chart = Ember.get(this, '_chart');
      if (chart) {
        chart.resize();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var chart = Ember.get(this, '_chart');
      if (chart) {
        chart.dispose();
      }
      $(window).off('resize');
    },
    bindEvents: function bindEvents(instance) {
      var events = Ember.get(this, 'onEvents') || {};
      var _loopEvent = function _loopEvent(eventName) {
        // ignore the event config which not satisfy
        if (typeof eventName === 'string' && typeof events[eventName] === 'function') {
          // binding event
          instance.off(eventName);
          instance.on(eventName, function (param) {
            events[eventName](param, instance);
          });
        }
      };

      for (var eventName in events) {
        if (Object.prototype.hasOwnProperty.call(events, eventName)) {
          _loopEvent(eventName);
        }
      }
    }
  });
});