define("ember-toggle/components/x-toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FhGPJq5J",
    "block": "{\"symbols\":[],\"statements\":[[6,\"input\"],[10,\"class\",\"x-toggle\"],[11,\"checked\",[20,\"toggled\"],null],[11,\"disabled\",[20,\"disabled\"],null],[11,\"id\",[20,\"forId\"],null],[11,\"name\",[20,\"name\"],null],[11,\"onchange\",[26,\"action\",[[21,0,[]],[22,[\"sendToggle\"]]],[[\"value\"],[\"target.checked\"]]],null],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\\n\"],[6,\"label\"],[11,\"for\",[20,\"effectiveForId\"],null],[8],[0,\"\\n  \"],[6,\"div\"],[11,\"id\",[27,[\"x-toggle-visual-\",[20,\"forId\"]]]],[10,\"role\",\"checkbox\"],[11,\"class\",[27,[\"x-toggle-btn \",[20,\"themeClass\"],\" \",[20,\"size\"],[26,\"if\",[[22,[\"disabled\"]],\" x-toggle-disabled\"],null]]]],[11,\"aria-owns\",[20,\"forId\"],null],[11,\"aria-checked\",[20,\"toggled\"],null],[11,\"data-tg-on\",[20,\"onLabel\"],null],[11,\"data-tg-off\",[20,\"offLabel\"],null],[8],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-toggle/components/x-toggle-switch/template.hbs"
    }
  });

  _exports.default = _default;
});