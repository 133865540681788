define("ember-power-select/templates/components/power-select/before-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6yuHf2pv",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"searchEnabled\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"ember-power-select-search\"],[8],[0,\"\\n    \"],[6,\"input\"],[10,\"autocomplete\",\"off\"],[10,\"autocorrect\",\"off\"],[10,\"autocapitalize\",\"off\"],[10,\"spellcheck\",\"false\"],[10,\"role\",\"combobox\"],[10,\"class\",\"ember-power-select-search-input\"],[11,\"value\",[22,[\"select\",\"searchText\"]],null],[11,\"aria-controls\",[20,\"listboxId\"],null],[11,\"placeholder\",[20,\"searchPlaceholder\"],null],[11,\"oninput\",[20,\"onInput\"],null],[11,\"onfocus\",[20,\"onFocus\"],null],[11,\"onblur\",[20,\"onBlur\"],null],[11,\"onkeydown\",[26,\"action\",[[21,0,[]],\"onKeydown\"],null],null],[10,\"type\",\"search\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/before-options.hbs"
    }
  });

  _exports.default = _default;
});