define("ember-power-select/templates/components/power-select/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T2B6xGL0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,[\"select\",\"selected\"]]],null,{\"statements\":[[4,\"if\",[[22,[\"selectedItemComponent\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"component\",[[22,[\"selectedItemComponent\"]]],[[\"extra\",\"option\",\"select\"],[[26,\"readonly\",[[22,[\"extra\"]]],null],[26,\"readonly\",[[22,[\"select\",\"selected\"]]],null],[26,\"readonly\",[[22,[\"select\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"class\",\"ember-power-select-selected-item\"],[8],[13,1,[[22,[\"select\",\"selected\"]],[22,[\"select\"]]]],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[26,\"and\",[[22,[\"allowClear\"]],[26,\"not\",[[22,[\"select\",\"disabled\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"class\",\"ember-power-select-clear-btn\"],[11,\"onmousedown\",[26,\"action\",[[21,0,[]],\"clear\"],null],null],[11,\"ontouchstart\",[26,\"action\",[[21,0,[]],\"clear\"],null],null],[8],[0,\"×\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[26,\"component\",[[22,[\"placeholderComponent\"]]],[[\"placeholder\"],[[22,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[6,\"span\"],[10,\"class\",\"ember-power-select-status-icon\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/trigger.hbs"
    }
  });

  _exports.default = _default;
});