define("ember-power-select/templates/components/power-select/placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EcZOkZup",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"placeholder\"]]],null,{\"statements\":[[0,\"  \"],[6,\"span\"],[10,\"class\",\"ember-power-select-placeholder\"],[8],[1,[20,\"placeholder\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/placeholder.hbs"
    }
  });

  _exports.default = _default;
});