define("ember-cli-flash/templates/components/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yy9c+UnZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,1]],null,{\"statements\":[[0,\"  \"],[13,1,[[21,0,[]],[22,[\"flash\"]],[26,\"action\",[[21,0,[]],\"close\"],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,[\"flash\",\"message\"]],false],[0,\"\\n\"],[4,\"if\",[[22,[\"showProgressBar\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",\"alert-progress\"],[8],[0,\"\\n      \"],[6,\"div\"],[10,\"class\",\"alert-progressBar\"],[11,\"style\",[20,\"progressDuration\"],null],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-flash/templates/components/flash-message.hbs"
    }
  });

  _exports.default = _default;
});