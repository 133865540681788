define("ember-power-select/templates/components/power-select/power-select-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8PkvlY0o",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"li\"],[10,\"class\",\"ember-power-select-group\"],[11,\"aria-disabled\",[26,\"ember-power-select-true-string-if-present\",[[22,[\"disabled\"]]],null],null],[10,\"role\",\"option\"],[8],[0,\"\\n  \"],[6,\"span\"],[10,\"class\",\"ember-power-select-group-name\"],[8],[1,[20,\"groupName\"],false],[9],[0,\"\\n  \"],[13,1],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/power-select-group.hbs"
    }
  });

  _exports.default = _default;
});