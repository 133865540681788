define("ember-toggle/components/x-toggle-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "23K6/99o",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,1]],null,{\"statements\":[[0,\"  \"],[13,1,[[22,[\"label\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[20,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-toggle/components/x-toggle-label/template.hbs"
    }
  });

  _exports.default = _default;
});