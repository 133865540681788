define("ember-basic-dropdown/templates/components/basic-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HW+IZXy2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[13,1,[[26,\"hash\",null,[[\"uniqueId\",\"isOpen\",\"disabled\",\"actions\",\"trigger\",\"content\"],[[22,[\"publicAPI\",\"uniqueId\"]],[22,[\"publicAPI\",\"isOpen\"]],[22,[\"publicAPI\",\"disabled\"]],[22,[\"publicAPI\",\"actions\"]],[26,\"component\",[[22,[\"triggerComponent\"]]],[[\"dropdown\",\"hPosition\",\"onFocus\",\"renderInPlace\",\"vPosition\"],[[26,\"readonly\",[[22,[\"publicAPI\"]]],null],[26,\"readonly\",[[22,[\"hPosition\"]]],null],[26,\"action\",[[21,0,[]],\"handleFocus\"],null],[26,\"readonly\",[[22,[\"renderInPlace\"]]],null],[26,\"readonly\",[[22,[\"vPosition\"]]],null]]]],[26,\"component\",[[22,[\"contentComponent\"]]],[[\"dropdown\",\"hPosition\",\"renderInPlace\",\"preventScroll\",\"rootEventType\",\"vPosition\",\"destination\",\"top\",\"left\",\"right\",\"width\",\"height\",\"otherStyles\"],[[26,\"readonly\",[[22,[\"publicAPI\"]]],null],[26,\"readonly\",[[22,[\"hPosition\"]]],null],[26,\"readonly\",[[22,[\"renderInPlace\"]]],null],[26,\"readonly\",[[22,[\"preventScroll\"]]],null],[26,\"readonly\",[[22,[\"rootEventType\"]]],null],[26,\"readonly\",[[22,[\"vPosition\"]]],null],[26,\"readonly\",[[22,[\"destination\"]]],null],[26,\"readonly\",[[22,[\"top\"]]],null],[26,\"readonly\",[[22,[\"left\"]]],null],[26,\"readonly\",[[22,[\"right\"]]],null],[26,\"readonly\",[[22,[\"width\"]]],null],[26,\"readonly\",[[22,[\"height\"]]],null],[26,\"readonly\",[[22,[\"otherStyles\"]]],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown.hbs"
    }
  });

  _exports.default = _default;
});